import React from 'react';
import ReactExport from "react-export-excel";
import { IconButton, Tooltip } from '@material-ui/core';
import ExportIcon from '@material-ui/icons/Assessment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export interface IExportAveragesProps {
    object: any;
}

const ExportAverages: React.FC<IExportAveragesProps> = ({ object }) => {

    let arr = [
        {
            avgGames: object.avgGames,
            avgPoints: object.avgPoints,
            avgWins: object.avgWins
        }
    ]

    return (
        <ExcelFile element={

            <IconButton>
                <Tooltip title="Export" aria-label="Export">
                    <ExportIcon />
                </Tooltip>
            </IconButton>
        }>
            <ExcelSheet data={arr} name="Averages">
                <ExcelColumn label="Avg Games" value="avgGames" />
                <ExcelColumn label="Avg Points" value="avgPoints" />
                <ExcelColumn label="Avg Wins" value="avgWins" />
            </ExcelSheet>
        </ExcelFile >
    );
}

export default ExportAverages;