import { Button, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import React, { useState, useContext, useEffect, Fragment } from 'react';
import DataProvider from '../../providers/DataProvider';
import FirebaseProvider from '../../providers/FirestoreProvider';
import ExcelDownload from '../exports/ExcelDownload'
import { useSnackbar } from 'notistack';

export interface IRecalculationsProps {
}

const Recalculations: React.FC<IRecalculationsProps> = ({ }) => {

    const dataprovider = new DataProvider();
    const { enqueueSnackbar } = useSnackbar();

    const [ loading, setLoading ] = useState(false);
    const [ exportColumns, setExportColumns ] = useState<any>([]);
    const [ exportData, setExportData ] = useState<any>([]);

    function recalculateUserAccuracy() {

        setLoading(true);

        const recalculateUserAccuracy = FirebaseProvider.functions().httpsCallable('recalculateUserAccuracy');
		const params = { 
            isTest: true,
            //companyId: "xtdYz2OrgyGxpwfn5KGT",
            //userId: "QEVMqvmZTvhYP03CPLP9TmMf0vz1"
            companyId: "U9oTvagHhWplt9ZqT1Fs",
            //userId: "IQU5Pnv0z9asdIWRuRMEtsBSJYu2"
		};

        console.log("recalculating for params = ", params)
        recalculateUserAccuracy(params)
        .then((response) => {

            console.log("recalculated succesfully")
            console.dir(response);
            setLoading(false);
            setExportColumns(response?.data?.csv?.columns || []);
            setExportData(response?.data?.csv?.rows || []);
            enqueueSnackbar("Completed the operation successfully", { variant: "success" });
            
        })
        .catch((error) => {
            
            console.log("recalculation failed")
            console.dir(error);
            setLoading(false);
            setExportColumns([]);
            setExportData([]);
            enqueueSnackbar("Failed to execute the operation: " + JSON.stringify(error),  { variant: "error" });

        });
        
    }

    return (
        <Fragment>
            <Container style={{ marginTop: "2em" }}>
                <Grid item container spacing={4} justify="center" alignItems="center" direction="column">

                    <Typography variant="h4">Recalculate User Accuracy</Typography>

                    <Grid item container justify="space-around" alignItems="center" direction="row">
                        
                        <Grid item xs={12} md={10}>
                            <Typography variant="body1">
                                Recalculate every user's accuracy based on the 'score' value of the items in
                                the user's 'Answers' collection
                            </Typography>
                        </Grid>
                        
                        <Grid item xs={12} md={2} container spacing={2} justify="space-around">
                            {
                                loading
                                ?   <CircularProgress />
                                :   <>
                                        <Grid item>
                                            <ExcelDownload columnsArr={exportColumns} dataArr={exportData} />
                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained' color="primary" onClick={recalculateUserAccuracy}>
                                                Recalculate
                                            </Button>
                                        </Grid>
                                    </>
                            }
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    );
}

export default Recalculations;