import React, { useState } from 'react';
import ReactExport from "react-export-excel";
import { Button, Typography } from '@material-ui/core';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export interface IExcelByPercentageProps {
    list: any;
}

const ExcelByPercentage: React.FC<IExcelByPercentageProps> = ({ list }) => {


    return (
        <ExcelFile element={<Button variant="outlined" size="small" style={{ backgroundColor: 'white' }}><Typography style={{ fontWeight: 600, fontSize: 14 }}>Export</Typography></Button>}>
            <ExcelSheet data={list} name="Game Stats">

                <ExcelColumn label="Percentage" value="percentage" />
                <ExcelColumn label="Points" value="points" />
                <ExcelColumn label="Games Played" value="gamesPlayed" />
                <ExcelColumn label="Time Played" value="timeSpent" />

            </ExcelSheet>
        </ExcelFile>
    );
}

export default ExcelByPercentage;