import React, {useEffect, useState} from 'react';
import ReactExport from "react-export-excel";
import { Button, Typography } from '@material-ui/core';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export interface ISuzukiReports {
    byUser: any[];
    byDealer: any[];
    byRegion: any[];
}

const SuzukiReports: React.FC<ISuzukiReports> = ({ byUser, byDealer, byRegion }) => {

    return (
        <ExcelFile element={<Button variant="outlined" size="small" style={{ backgroundColor: 'white' }}><Typography style={{ fontWeight: 600, fontSize: 14 }}>Export</Typography></Button>}>
            <ExcelSheet data={byUser?.length > 0 ? byUser : []} name="ByUser">
                <ExcelColumn label="First Name" value="firstName" />
                {/* Surname */}
                <ExcelColumn label="Surname" value="lastName" />
                {/* Email */}
                <ExcelColumn label="Email" value="email" />
                {/* General */}
                <ExcelColumn label="General" value="general" />
                {/* Game Name */}
                <ExcelColumn label="Game Name" value="gameName" />
                {/* Game Date */}
                <ExcelColumn label="Game Date" value="gameDate" />
                {/* Accuracy */}
                <ExcelColumn label="Accuracy" value="accuracy" />
                {/* Top Points */}
                <ExcelColumn label="Top Points" value="points" />
                {/* Region */}
                <ExcelColumn label="Region" value="region" />
            </ExcelSheet>
            <ExcelSheet data={byDealer?.length > 0 ? byDealer : []} name="ByDealer">                
            <ExcelColumn label="First Name" value="firstName" />
                {/* Surname */}
                <ExcelColumn label="Surname" value="lastName" />
                {/* Email */}
                <ExcelColumn label="Email" value="email" />
                {/* General */}
                <ExcelColumn label="General" value="general" />
                {/* Accuracy */}
                <ExcelColumn label="Accuracy" value="accuracy" />
                {/* Top Points */}
                <ExcelColumn label="Top Points" value="points" />
                {/* Dealer % */}
                <ExcelColumn label="Overall Dealer %" value="dealerPercentage" />
                {/* Region */}
                <ExcelColumn label="Region" value="region" />
            </ExcelSheet>
            <ExcelSheet data={byRegion?.length > 0 ? byRegion : []} name="ByRegion">
            <ExcelColumn label="First Name" value="firstName" />
                {/* Surname */}
                <ExcelColumn label="Surname" value="lastName" />
                {/* Email */}
                <ExcelColumn label="Email" value="email" />
                {/* General */}
                <ExcelColumn label="General" value="general" />
                {/* Accuracy */}
                <ExcelColumn label="Accuracy" value="accuracy" />
                {/* Top Points */}
                <ExcelColumn label="Top Points" value="points" />
                {/* Region */}
                <ExcelColumn label="Region" value="region" />
            </ExcelSheet>
        </ExcelFile>
    );
}

export default SuzukiReports;
