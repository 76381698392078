import React from 'react';
import ReactExport from "react-export-excel";
import { IconButton, Tooltip } from '@material-ui/core';
import ExportIcon from '@material-ui/icons/Assessment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export interface IExportByTopAccuracyProps {
    list: any[];
}

const ExportByTopAccuracy: React.FC<IExportByTopAccuracyProps> = ({ list }) => {


    return (
        <ExcelFile element={
            <IconButton>
                <Tooltip title="Export" aria-label="Export">
                    <ExportIcon />
                </Tooltip>
            </IconButton>}>
            <ExcelSheet data={list} name="Game Stats">
                <ExcelColumn label="Name" value="name" />
                <ExcelColumn label="Surname" value="surname" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="General" value="general" />
                <ExcelColumn label="Accuracy" value="accuracy" />
            </ExcelSheet>
        </ExcelFile >
    );
}

export default ExportByTopAccuracy;