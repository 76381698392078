import React, { useState } from 'react';
import ReactExport from "react-export-excel";
import { Button, Typography } from '@material-ui/core';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

enum State {
    LOADING = 0,
    GENERATING,
    ERROR,
    EMPTY,
    SUCCESS,
    INITIAL
}

export interface IExcelAllProps {
    report: any;
    state: State
}

const ExcelAll: React.FC<IExcelAllProps> = ({ report, state }) => {

    const top10: any = report.top10;
    let topAccuracy: any[] = [];
    let topPoints: any[] = [];
    let topGamesPoints: any[] = [];

    if (top10) {
        topAccuracy = top10.topAccuracy;
        topPoints = top10.topPoints;
        topGamesPoints = top10.topGamesPlayed;
    }

    const toPercentageArr = (object: any) => {
        let arr: any[] = [];

        arr[0] = {
            ...object.bottom25,
            percentage: "Bottom 25%"
        };

        arr[1] = {
            ...object.mid50,
            percentage: "Mid 50%"
        };

        arr[2] = {
            ...object.top25,
            percentage: "Top 25%"
        };

        return arr;

    }

    if (state.valueOf() !== 4) {
        return <></>
    }


    return (
        <ExcelFile element={<Button variant="outlined" size="small" ><Typography style={{ fontWeight: 600, fontSize: 14 }}>Export Page</Typography></Button>}>
            <ExcelSheet data={report?.byDepartment} name="By Department">
                <ExcelColumn label="Department Name" value="name" />
                <ExcelColumn label="Number of Players" value="userCount" />
                <ExcelColumn label="Active players" value="activePlayerCount" />
                <ExcelColumn label="Average Points" value="averagePoints" />
            </ExcelSheet>

            <ExcelSheet data={report?.gameStats} name="By Game">
                <ExcelColumn label="Name" value="gameName" />
                <ExcelColumn label="Games Played" value="gamesPlayed" />
                <ExcelColumn label="Answer Accuracy" value="averageAccuracy" />
                <ExcelColumn label="Play Time" value="timePlayed" />
            </ExcelSheet>

            <ExcelSheet data={topAccuracy} name="Top Accuracy">
                <ExcelColumn label="Name" value="name" />
                <ExcelColumn label="Surname" value="surname" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="General" value="general" />
                <ExcelColumn label="Accuracy" value="accuracy" />
            </ExcelSheet>

            <ExcelSheet data={topPoints} name="Top Points">
                <ExcelColumn label="Name" value="name" />
                <ExcelColumn label="Surname" value="surname" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="General" value="general" />
                <ExcelColumn label="Points" value="points" />
            </ExcelSheet>

            <ExcelSheet data={topGamesPoints} name="Top Games Played">
                <ExcelColumn label="Name" value="name" />
                <ExcelColumn label="Surname" value="surname" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="General" value="general" />
                <ExcelColumn label="Games Played" value="gamesPlayed" />
            </ExcelSheet>

            {report.percentages != null && <ExcelSheet data={toPercentageArr(report.percentages)} name="By Percentages">
                <ExcelColumn label="Percentage" value="percentage"/>
                <ExcelColumn label="Points" value="points"/>
                <ExcelColumn label="Games Played" value="gamesPlayed"/>
                <ExcelColumn label="Time Played" value="timeSpent"/>
            </ExcelSheet>}
        </ExcelFile>
    );
}

export default ExcelAll;
