import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import DataProvider from '../../providers/DataProvider';
import { AuthContext } from '../../providers/AuthProvider';
import { Button, Typography, Grid, Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import MainLayout from '../../components/MainLayout';
import Filter from '../../components/Filter';
import AllActions from '../../redux/actions/Index';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import PopupConfirm from '../../components/PopupConfirm';
import ExcelExportButton from '../../components/ExcelExportButton'

const UserList = () => {

    const dataProvider = new DataProvider();

    const [ loading, setLoading ] = useState(true);
    const [ allUsers, setAllUsers ] = useState<any[]>([]);
    const [ users, setUsers ] = useState<any[]>([]);
    const [ openConfirm, setOpenConfirm ] = useState(false);
    const [ deleteUser, setDeleteUser ] = useState<any>({});
    const [ exportColumns, setExportColumns ] = useState<any>([]);
    const [ exportData, setExportData ] = useState<any>([]);
    
    const { user } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    let searchField = "";
    let numSearchCalled = 0;
    let numSearched = 0;
    let history = useHistory();

    useEffect(() => {
        
        // Get all the users, then filter by company & department upon selection
        dataProvider.getAllUsers(user)
        .then(resultUsers => {
            
            let columns = [
                { title: "CompanyID", name: "companyId"},
                { title: "Company Name", name: "companyName"},
                { title: "DepartmentID", name: "departmentId"},
                { title: "Department Name", name: "departmentName"},
                { title: "UserID", name: "userId"},
                { title: "Email", name: "email"},
                { title: "First Name", name: "firstName"},
                { title: "Last Name", name: "lastName"},
                { title: "ID Number", name: "idNumber"},
                { title: "Role", name: "role"},
                { title: "Is Disabled", name: "disabled"},
                { title: "Games Played", name: "gamesPlayed"},
                { title: "Accuracy", name: "accuracy"},
                { title: "Total Points", name: "totalPoints"},
                { title: "General", name: "general"},
            ];
    
            setExportColumns(columns);
            setAllUsers(resultUsers);
            setLoading(false);
            
        })
        .catch(error => {
            setLoading(false);
            enqueueSnackbar(`Failed to retrieve all the users: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        });

    }, []);

    useEffect(() => {
        
        let filteredUsers = getFilteredUsers(searchState.company?.id, searchState.department?.id);
        setUsers(filteredUsers);

    }, [allUsers]);

    useEffect(() => {
        
        assignExportData();
        setLoading(false);

    }, [users]);

    const disableEdit = (rowData: any) => {
        return rowData.role !== 1;
    }

    const getFilteredUsers = (companyId: any, departmentId: any) => {
        
        return allUsers.filter(u => u.companyId == companyId && (!departmentId || u.departmentId == departmentId));

    };

    const assignExportData = () => {

        // Only process the searched text after the timer has been executed the same number of times it has been called
        if (numSearched != numSearchCalled)
            return;

        numSearchCalled = 0;
        numSearched = 0;

        if (!searchField || searchField.trim().length <= 0) {
            
            setExportData(users);

        } else {

            let searchText = searchField.toLocaleLowerCase().trim();

            let searchedUsers = users.filter(u => 
                u.firstName.includes(searchText) ||
                u.lastName.includes(searchText) ||
                u.departmentName.includes(searchText) ||
                u.general.includes(searchText) ||
                u.email.includes(searchText)
            );

            setExportData(searchedUsers);

        }

    };

    const handleClose = () => {
		setOpenConfirm(false);
	};

    const handelConfirm = () => {
        
        setLoading(true);
        setOpenConfirm(false);

        let currentUser = {
            departmentId: user?.department,
            role: user?.role
        };
        
        dataProvider.removeUser(deleteUser, currentUser)
            .then(() => {
                
                // Remove the user from the list
                let i = allUsers.findIndex(u => u.userId == deleteUser.userId);
                allUsers.splice(i, 1);
                
                let filteredUsers = getFilteredUsers(deleteUser.companyId, deleteUser.departmentId);
                setUsers(filteredUsers);

                enqueueSnackbar(`User ${deleteUser.email} succesfully deleted`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                
            })
            .catch(errorMsg => {
                setLoading(false);
                enqueueSnackbar(errorMsg, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            });
    };

    return (
        <MainLayout
            actions={[
                <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => { history.push("/users/addBulk") }}>Add bulk</Button>,
                <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => { history.push("/users/add") }}>add user</Button>,
                <ExcelExportButton fileName="Users" buttonTitle="Export Users" columnsArr={exportColumns} dataArr={exportData} />,
            ]}
            filters={[
                <Filter loading={loading} allowCompanyOnlySearch={true} onSubmit={(company: any, department: any) => {
                    
                    if (company) {
                        setLoading(true);

                        // Filter the users by company & department
                        let filteredUsers = getFilteredUsers(company.id, department?.id);
                        setUsers(filteredUsers);
                        dispatch(AllActions.searchState.setSearchState({ company: company, department: department }));
                        
                    }

                }} />
            ]}
            content={
                <Fragment>
                    <MaterialTable 
                        title="Users"
                        data={users}
                        isLoading={loading}
                        style={{ width: '100%' }}
                        options={{ search: true }}
                        onSearchChange={(props) => { 
                            numSearchCalled++;
                            searchField = props;
                            setTimeout(() => {
                                numSearched++;
                                assignExportData(); 
                            }, 500);
                        }}
                        columns={[
                            {
                                title: "Name",
                                render: rowData => (
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item>
                                            <Typography noWrap>{rowData.firstName}</Typography>
                                        </Grid>
                                        <Grid item>
                                            {
                                                rowData.disabled ?
                                                    <Chip label="Disabled" />
                                                    :
                                                    <></>
                                            }
                                            {
                                                rowData.role === 2 ?
                                                    <Chip label="Manager" />
                                                    :
                                                    <></>
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            },
                            { title: "Surname", field: "lastName" },
                            { title: "Department", field: "departmentName" },
                            { title: "General", field: "general"},
                            { title: "Email", field: "email" }
                        ]}
                        actions={[
                            rowData => (
                                {
                                    icon: 'edit',
                                    tooltip: 'Edit User',
                                    onClick: (event, rowData) => {
                                        //@ts-ignore
                                        history.push("/users/edit/" + rowData.userId)
                                    },
                                    disabled: disableEdit(rowData)
                                }
                            ),                        
                            {
                                icon: 'delete',
                                tooltip: 'Delete User',
                                onClick: (event, rowData) => {

                                    setDeleteUser({
                                        role: rowData.role,
                                        email: rowData.email,
                                        userId: rowData.userId,
                                        companyId: rowData.companyId,
                                        departmentId: rowData.departmentId
                                    });

                                    setOpenConfirm(true);

                                }
                            }                        
                        ]}
                    />
                    <PopupConfirm
                        contentText="This action can not be reverted!"
                        isOpen={openConfirm}
                        title="Are you sure you want to delete this item?"
                        handleClose={handleClose}
                        handleConfirm={handelConfirm}
                    />
                </Fragment>
            }
        />
    );
};

export default UserList;
