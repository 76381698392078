import React, { useState, useEffect } from 'react';
import ReactExport from "react-export-excel";
import { Button, Typography } from '@material-ui/core';
import MultipleChoice from './AddMultipleChoice';
import FillInTheBlank from './AddFillInTheBlank';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export interface IExcelByQuestionsProps {
    list: any[];
}

const ExcelByQuestions: React.FC<IExcelByQuestionsProps> = ({ list }) => {

    const [multiChoice, setMulti] = useState<any[]>([]);
    const [fillInTheBlank, setFillInTheBlank] = useState<any[]>([]);
    const [bucket, setBucket] = useState<any[]>([]);
    const [matchTerms, setMatchTerms] = useState<any[]>([]);

    const stringify = (arr: any[]) => {

        let bucket = "";

        for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            if (bucket === "") {
                bucket = element.name;
            } else {
                bucket = bucket + "," + element.name;
            }
        }
        return bucket;
    }

    const stringifyMatchTheTerms = (arr: any[]) => {

        let terms = "";

        for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            if (terms === "") {
                terms = element.name;
            } else {
                terms = bucket + "," + element.name;
            }

        }

        return bucket;
    }

    const mapQuestion = (item: any) => {
        switch (item.questionType) {
            case 0:
                return {
                    Question: item.questionText,
                    answer1: item.answers[0],
                    answer2: item.answers[1],
                    answer3: item.answers[2],
                    answer4: item.answers[3],
                    correctAnswer: item.correctAnswerIndex + 1
                }
            case 1:
                return {
                    Question: item.questionText,
                    answer1: item.answers[0],
                    answer2: item.answers[1],
                    answer3: item.answers[2],
                    answer4: item.answers[3],
                    correctAnswer: item.correctAnswerIndex + 1
                }
            case 2:
                let matchTerms1 = item.terms[0].items;
                let matchTerms2 = item.terms[1].items;

                let terms1 = stringify(matchTerms1);
                let terms2 = stringify(matchTerms2);

                let object = {
                    question: item.questionText,
                    terms1: terms1,
                    terms2: terms2
                }

                return object;
            case 3:

                let items: any[] = item.items;

                let bucket1: string = stringify([...items].filter(x => x.matchIndex === 0));
                let bucket2: string = stringify([...items].filter(x => x.matchIndex === 1));

                return {
                    question: item.questionText,
                    bucket1: item.buckets[0].name,
                    bucket2: item.buckets[1].name,
                    bucket1Items: bucket1,
                    bucket2Items: bucket2,
                }
            default:
                return null;
                break;
        }
    }

    const mapList = (arr: any[]) => {

        let multiChoiceArr: any[] = [];
        let fillInTheBlankArr: any[] = [];
        let matchTheTermsArr: any[] = [];
        let wordBucketArr: any[] = [];

        console.log("In mapping list");


        for (let i = 0; i < arr.length; i++) {
            console.log("In For loop");
            const element = arr[i];
            console.log("question not mapped", element);
            let question = mapQuestion(element);
            console.log("question mapped", question);

            if (question !== null) {

                switch (element.questionType) {
                    case 0:
                        multiChoiceArr.push(question);
                        break;
                    case 1:
                        fillInTheBlankArr.push(question);
                        break;
                    case 2:
                        matchTheTermsArr.push(question);
                        break;
                    case 3:
                        wordBucketArr.push(question);
                        break;
                    default:
                        break;
                }
            }
        }

        setBucket([...wordBucketArr]);
        setMulti([...multiChoiceArr]);
        setFillInTheBlank([...fillInTheBlankArr]);
        setMatchTerms([...matchTheTermsArr]);
    }

    useEffect(() => {
        console.log("In use Effect");
        mapList(list);
    }, [list]);


    return (
        <ExcelFile element={<Button variant="contained" color="primary">Export Questions</Button>}>
            <ExcelSheet data={multiChoice} name="Multiple Choice">
                <ExcelColumn label="Question" value="Question" />
                <ExcelColumn label="answer 1" value="answer1" />
                <ExcelColumn label="answer 2" value="answer2" />
                <ExcelColumn label="answer 3" value="answer3" />
                <ExcelColumn label="answer 4" value="answer4" />
                <ExcelColumn label="Correct answer" value="correctAnswer" />
            </ExcelSheet>
            <ExcelSheet data={fillInTheBlank} name="Fill in the blank">
                <ExcelColumn label="Question" value="Question" />
                <ExcelColumn label="answer 1" value="answer1" />
                <ExcelColumn label="answer 2" value="answer2" />
                <ExcelColumn label="answer 3" value="answer3" />
                <ExcelColumn label="answer 4" value="answer4" />
                <ExcelColumn label="Correct answer" value="correctAnswer" />
            </ExcelSheet>
            <ExcelSheet data={bucket} name="Word bucket">
                <ExcelColumn label="Question" value="question" />
                <ExcelColumn label="First Bucket name" value="bucket1" />
                <ExcelColumn label="Second Bucket name" value="bucket2" />
                <ExcelColumn label="First Bucket items" value="bucket1Items" />
                <ExcelColumn label="Second Bucket items" value="bucket2Items" />
            </ExcelSheet>
            <ExcelSheet data={matchTerms} name="Match the terms">
                <ExcelColumn label="Question" value="question" />
                <ExcelColumn label="Terms 1" value="terms1" />
                <ExcelColumn label="Terms 2" value="terms2" />
            </ExcelSheet>
        </ExcelFile>
    );
}

export default ExcelByQuestions;