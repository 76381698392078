import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid } from '@material-ui/core';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import { useParams, useHistory } from 'react-router-dom';
import Panel from '../../components/Panel';
import DataProvider from '../../providers/DataProvider';
import ImageUpload from '../../components/ImageUpload';
import * as Yup from 'yup';
import FormFooter from '../../components/FormFooter';
import { useSelector } from 'react-redux';
import EditbleMediaSelector from '../../components/MediaSelectorEditable';
import { useSnackbar } from 'notistack';

const validation = Yup.object().shape({
    name: Yup.string().required('Required'),
    subtitle: Yup.string().required('Required')
});

export default function EditGame() {

    const { enqueueSnackbar } = useSnackbar();

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    const dataProvider = new DataProvider();
    let params: any = useParams();
    let history = useHistory();

    const [imageChanged, setImageChanged] = useState(false);
    const [initialValues, setInitialValues] = useState({
        name: "",
        subtitle: "",
        image: "",
        media: [],
        isExam: false,
        learningTopic: "",
        passRate: "",
        gameRetries: 0,
        categoryRetries: 0,
        order: 0,
        mustWatchVideo: false
    });
    const [loading, setLoading] = useState(true);
    const [currentGame, setGame] = useState<any>();
    const [initialMedia, setInitialMedia] = useState<any[]>([]);
    const [mediaError, setMediaError] = useState<boolean>();
    const [learningTopic, setLearningTopic] = useState<any | undefined>(undefined);

    useEffect(() => {

        if (searchState.company.id && searchState.department.id) {
            dataProvider.getGame(searchState.company.id, searchState.department.id, params.id).then(data => {

                setGame(data);

                //setLearningTopic(data.learningTopic);
                let initial: any = {
                    name: data.name,
                    subtitle: data.subtitle,
                    image: data.gameImgUrl,
                    media: data.media,
                    isExam: data.isExam,
                    learningTopic: data.learningTopic,
                    order: data.order ? data.order : 0,
                    passRate: data.passRate ? data.passRate : 0,
                    mustWatchVideo: data.mustWatchVideo ? data.mustWatchVideo : false,
                    gameRetries: data.gameRetries ? data.gameRetries : 0,
                    categoryRetries: data.categoryRetries ? data.categoryRetries : 0
                }
                setLearningTopic(data.learningTopic);

                //@ts-ignore
                setInitialMedia(data.media);
                setInitialValues(initial);
                setLoading(false);
            });
        } else {
            history.push("/games");
        }
        //eslint-disable-next-line
    }, []);

    return (
        <Panel isLoading={loading}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={async (values, { setSubmitting }) => {
                            
                    if (!(values?.media?.length > 0)) {
                        enqueueSnackbar("No media is provided", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        setSubmitting(false);
                        return;
                    }

                    if (values?.mustWatchVideo && !values?.media?.some((m: any) => m.mediaType.trim().toLowerCase() == "video")) {
                        enqueueSnackbar("No video is uploaded", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        setSubmitting(false);
                        return;
                    }
                    
                    setSubmitting(true);

                    let defaultShared = {
                        isHost: false,
                        isChild: false,
                        hostId: null,
                        departments: []
                    }

                    let game: any = {
                        name: values.name,
                        subtitle: values.subtitle,
                        gameImgUrl: values.image,
                        gameImgPath: currentGame.gameImgPath,
                        maximumCorrect: currentGame.maximumCorrect,
                        completetionThresholdPrecent: "70",
                        media: values.media,
                        isExam: learningTopic || false,
                        learningTopic: learningTopic || null,
                        shared: currentGame.shared || defaultShared,
                        order: values.order || 0,
                        passRate: values.passRate || 0,
                        mustWatchVideo: values.mustWatchVideo || false,
                        gameRetries: values.gameRetries || 0,
                        categoryRetries: values.categoryRetries || 0
                    };

                    try {

                        if (imageChanged) {
                            let { imgUrl, imgPath } = await dataProvider.addImage(values.image, "games_images/");

                            game.gameImgUrl = imgUrl;
                            game.gameImgPath = imgPath;
                        }

                        //If the game was not a exam
                        if (currentGame.isExam === false) {
                            await dataProvider.editGame(searchState.company.id, searchState.department.id, game, params.id);
                        } else {

                            let editedLearningTopic = learningTopic || { id: "", name: "" };

                            //If learning center topic changed
                            if (currentGame.learningTopic.id !== editedLearningTopic.id) {
                                await dataProvider.editGame(searchState.company.id, searchState.department.id, game, params.id, currentGame.learningTopic);
                            } else {
                                await dataProvider.editGame(searchState.company.id, searchState.department.id, game, params.id);
                            }
                        }

                        history.goBack();
                        setSubmitting(false);
                    } catch (error : any) {
                        alert(error.message);
                        console.log(error);
                    }


                }}
            >
                {({ submitForm, isSubmitting, values, setFieldValue }) => (
                    <Form noValidate>
                        <Grid container direction='column'>
                            <Grid item container spacing={2}>
                                <Grid item xs={4}>
                                    <ImageUpload
                                        header="Game Image"
                                        id="image_gameImage"
                                        src={values.image}
                                        onChange={(file: any) => {
                                            setImageChanged(true);
                                            setFieldValue("image", file);
                                        }}
                                    />
                                </Grid>
                                <Grid item container xs={8} direction="column">
                                    <Grid container item xs={6} style={{ padding: '1px' }}>
                                        <Grid item>
                                            <Field
                                                required
                                                component={TextField}
                                                type="text"
                                                label="Name"
                                                name="name"
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                required
                                                component={TextField}
                                                type="text"
                                                label="Subtitle"
                                                name="subtitle"
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item >
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Display Order"
                                                name="order"
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item >
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Game Retries per day"
                                                name="gameRetries"
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item >
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Category Retries per day"
                                                name="categoryRetries"
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item >
                                            <Field
                                                component={CheckboxWithLabel}
                                                indeterminate={false}
                                                type="checkbox"
                                                name="mustWatchVideo"
                                                Label={{ label: 'Must watch video before playing' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} style={{ padding: '1px' }}>

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <EditbleMediaSelector
                                    dataPath={`/Companies/${searchState.company.id}/Departments/${searchState.department.id}/Games`}
                                    error={mediaError}
                                    onMediaChange={(arr: any[]) => {
                                        setMediaError(arr.length > 0 ? false : true);
                                        setFieldValue("media", arr);
                                    }}
                                    initialState={initialMedia}
                                />
                            </Grid>
                            <Grid item>
                                <FormFooter
                                    onAction={submitForm}
                                    submitting={isSubmitting}
                                    actionName="Save"
                                    onBack={() => { history.goBack() }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )
                }
            </Formik >
        </Panel >
    );
}
