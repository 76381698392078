import React from 'react';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export interface IExcelDownloadProps {
    columnsArr: any;
    dataArr: any;
}

const ExcelDownload: React.FC<IExcelDownloadProps> = ({ columnsArr, dataArr }) => {

    if (columnsArr == null || columnsArr == undefined || columnsArr.length <= 0) {
        return (
            <div>No valid columns provided</div>
        )
    } else if (dataArr == null || dataArr == undefined || dataArr.length <= 0) {
        return (
            <div>No valid data rows provided</div>
        )
    } else {
        return (
            <ExcelFile>
                <ExcelSheet data={dataArr} name="Sheet 1">
                    {
                        columnsArr.map((columnInfo:any) => {
                           return (<ExcelColumn label={columnInfo.title} value={columnInfo.name} />)
                        })
                    }
                </ExcelSheet>
            </ExcelFile>
        );
    }

}

export default ExcelDownload;