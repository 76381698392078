import React, { useState, useEffect } from 'react';
import ReactExport from "react-export-excel";
import { Button } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export interface IExcelExportButton {
    fileName: string;
    buttonTitle: string;
    columnsArr: any;
    dataArr: any;
}

const ExcelExportButton: React.FC<IExcelExportButton> = ({ fileName, buttonTitle, columnsArr, dataArr }) => {

    const [ hasAvailableData, setHasAvailableData ] = useState(false);

    useEffect(() => {

        let hasColumnData = (columnsArr != null && columnsArr != undefined && columnsArr.length > 0);
        let hasRowData = (dataArr != null && dataArr != undefined && dataArr.length > 0);

        setHasAvailableData((hasColumnData && hasRowData));  

    }, [columnsArr, dataArr]);

    return (
        /*
         *  NOTE (2021-10-06):
         *  The disable attribute in the ExcelFile's Button element successfully disables the button, 
         *  but if it is clicked while disabled then the export is still performed. Can't figure out why,
         *  so the work-araound is to duplicate the button based and have nothing assigned to its onClick handler
         */
        <>{
            hasAvailableData ?
                <ExcelFile
                    filename={fileName}
                    element={
                        <Button 
                            variant="contained" 
                            color="primary" 
                            startIcon={<ImportExportIcon />}
                            disabled={!hasAvailableData}
                        >
                            {buttonTitle}
                        </Button>
                    }
                >
                    <ExcelSheet data={dataArr} name="Sheet 1">
                        {
                            columnsArr.map((columnInfo:any) => {
                            return (<ExcelColumn label={columnInfo.title} value={columnInfo.name} />)
                            })
                        }
                    </ExcelSheet>
                </ExcelFile>
            :
                <Button 
                    variant="contained" 
                    color="primary" 
                    startIcon={<ImportExportIcon />}
                    disabled
                >
                    {buttonTitle}
                </Button>
        }</>
    );
}

export default ExcelExportButton;