import React, { useState } from 'react';
import ReactExport from "react-export-excel";
import { Button, Typography } from '@material-ui/core';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export interface IExcelByGameProps {
    list: any[];
}

const ExcelByGame: React.FC<IExcelByGameProps> = ({ list }) => {


    return (
        <ExcelFile element={<Button variant="outlined" size="small" style={{ backgroundColor: 'white' }}><Typography style={{ fontWeight: 600, fontSize: 14 }}>Export</Typography></Button>}>
            <ExcelSheet data={list} name="Game Stats">
                <ExcelColumn label="Name" value="gameName" />
                <ExcelColumn label="Games Played" value="gamesPlayed" />
                <ExcelColumn label="Answer Accuracy" value="averageAccuracy" />
                <ExcelColumn label="Play Time" value="timePlayed" />
            </ExcelSheet>
        </ExcelFile>
    );
}

export default ExcelByGame;