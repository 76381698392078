import React, { useState } from 'react';
import ReactExport from "react-export-excel";
import { Button, Typography, IconButton, Tooltip } from '@material-ui/core';
import ExportIcon from '@material-ui/icons/Assessment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export interface IExcelInactiveUsersProps {
    list: any[];
}

const ExcelInactiveUsers: React.FC<IExcelInactiveUsersProps> = ({ list }) => {

    let arr = list.map(item => {
        return {
            name: item.userInfo.firstName,
            surname: item.userInfo.lastName,
            email: item.userInfo.email,
            general: item.userInfo.general
        }
    })


    return (
        <ExcelFile element={
            <IconButton>
                <Tooltip title="Export" aria-label="Export">
                    <ExportIcon />
                </Tooltip>
            </IconButton>
        }>
            <ExcelSheet data={arr} name="Inactive users">
                <ExcelColumn label="Name" value="name" />
                <ExcelColumn label="Surname" value="surname" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="General" value="general" />
            </ExcelSheet>
        </ExcelFile >
    );
}

export default ExcelInactiveUsers;