import React, { useState } from 'react';
import ReactExport from "react-export-excel";
import { Button, Typography } from '@material-ui/core';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export interface IExcelByDepartmentProps {
    list: any[];
}

const ExcelByDepartment: React.FC<IExcelByDepartmentProps> = ({ list }) => {


    return (
        <ExcelFile element={<Button variant="outlined" size="small" style={{ backgroundColor: 'white' }}><Typography style={{ fontWeight: 600, fontSize: 14 }}>Export</Typography></Button>}>
            <ExcelSheet data={list} name="Employees">

                <ExcelColumn label="Department Name" value="name" />
                <ExcelColumn label="Number of Players" value="userCount" />
                <ExcelColumn label="Active players" value="activePlayerCount" />
                <ExcelColumn label="Average Points" value="averagePoints" />

            </ExcelSheet>
        </ExcelFile>
    );
}

export default ExcelByDepartment;