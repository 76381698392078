import React from 'react';
import ReactExport from "react-export-excel";
import { Button, Typography } from '@material-ui/core';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export interface IExportDefault {
    data: any[];
    columns: any[];
}

const ExportDefault: React.FC<IExportDefault> = ({ data, columns }) => {

    return (
        <ExcelFile element={<Button variant="outlined" size="small" style={{ backgroundColor: 'white' }}><Typography style={{ fontWeight: 600, fontSize: 14 }}>Export</Typography></Button>}>
            <ExcelSheet data={data} name="UserAnswers">
                {
                    columns?.length > 0 && columns.map((c) => (
                        <ExcelColumn label={c.title} value={c.field} />
                    ))
                }
            </ExcelSheet>
        </ExcelFile>
    );
}

export default ExportDefault;